import { useState } from "react";
import { COLOR, LANG } from "../../common/constant";
import GameCanvas from "../../components/GameCanvas";
import SvgButton from "../../components/SvgButton";
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import Image from "mui-image";
const { detect } = require('detect-browser');
const browser = detect();

export default function GameEnd() {
  const browserOs = (browser.os).toLocaleLowerCase().split(" ") ;
  const isDesktop = !(browserOs.includes('android') || browserOs.includes('ios'));

  const location = useLocation();
  const [lang, setLang] = useState(
    location.state && location.state.lang ? location.state.lang : LANG.CHI
  );

  const onGreenClicked = () => {
  };
  const onYellowClicked = () => {
  };

  const toggleLang = () => {
    setLang(lang === LANG.CHI ? LANG.ENG : LANG.CHI);
  };

  return (
    <>
      <Dialog open={true}
        BackdropProps={{ style: { backgroundColor: "rgba(255,255,255,0.5)" } }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}>
        <DialogContent>
          <Image
            src="images/game_end.png"
            duration={0}
          />
        </DialogContent>
      </Dialog>
      <GameCanvas lang={lang}>
        <Box
          display="flex"
          position="relative"
          justifyContent="center"
          alignItems="flex-start"
          width="100%"
          height="75%"
        >
          <Box
            display="flex"
            position="absolute"
            top="0"
            width="100%"
            height="75%"
            justifyContent="center"
            alignItems="flex-start"
            bgcolor={COLOR.BACKGROUND.WHITE}
          ></Box>
          <Box
            display="flex"
            position="absolute"
            top="0"
            left="0"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/Character_A_300.png"
              duration={0}
              width={ isDesktop ? "16.5vh" : "15vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="-1.5%"
            right="-2%"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/Character_C_300.png"
              duration={0}
              width={ isDesktop ? "23vh" : "20vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="22vh"
            left="-1vh"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/Character_B_300.png"
              duration={0}
              width={ isDesktop ? "22.5vh" : "20vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="33%"
            right="-5%"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/Character_D_300.png"
              duration={0}
              width={ isDesktop ? "22vh" : "20vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="13%"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/xmas.svg"
              duration={0}
              width={ isDesktop ? "28vh" : "25vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            bottom="0"
            width="100%"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Grid container>
              <Grid item xs={6} sx={{ p: 1 }}>
                <SvgButton
                  buttonStyle={(lang === LANG.ENG ? "Btn01_En":"Btn01_Ch")}
                  textSize="2.5vh"
                  onClick={onGreenClicked}
                />
              </Grid>
              <Grid item xs={6} sx={{ p: 1 }}>
                <SvgButton
                  buttonStyle={(lang === LANG.ENG ? "Btn02_En":"Btn02_Ch")}
                  textSize="2.5vh"
                  onClick={onYellowClicked}
                />
              </Grid>
              <Grid item xs={6} padding={0}>
                <Box
                  padding={0}
                  margin={0}
                  onClick={toggleLang}
                  marginLeft="3vh"
                  marginBottom="3vh"
                >
                  <Typography
                    style={{
                      fontVariant: "normal",
                      fontWeight: 900,
                      fontStretch: "normal",
                      fontSize: "2vh",
                      fontFamily:
                        "'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                      color: "#ffffff",
                      marginTop: "0px",
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        cursor: "hand",
                      },
                    }}
                  >
                    {lang !== LANG.ENG ? "ENG" : "中文"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </GameCanvas>
    </>
  );
}
