import { useState } from "react";
import { COLOR, BUTTON_ACTION_DELAY, LANG } from "../../common/constant";
import GameCanvas from "../../components/GameCanvas";
import SvgButton from "../../components/SvgButton";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate, useLocation } from "react-router-dom";
import Image from "mui-image";
const { detect } = require('detect-browser');
const browser = detect();

export default function MainManu() {
  const browserOs = (browser.os).toLocaleLowerCase().split(" ") ;
  const isDesktop = !(browserOs.includes('android') || browserOs.includes('ios'));

  let navigate = useNavigate();

  const location = useLocation();
  const [lang, setLang] = useState(
    location.state && location.state.lang ? location.state.lang : LANG.CHI
  );

  const [needTnC, setNeedTnC] = useState(
    location.state && location.state.needTnC ? location.state.needTnC : "Y"
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [openGameRuleDialog, setOpenGameRuleDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleGameRuleOpenDialog = () => {
    setOpenGameRuleDialog(true);
  };

  const handleGameRuleCloseDialog = () => {
    setOpenGameRuleDialog(false);
  };

  const onGreenClicked = () => {
    setTimeout(function () {
      // if (needTnC === "Y") {
      //   setNeedTnC("N");
      //   handleOpenDialog();
      // } else {
        if (document.getElementById("bgm")) {
          document.getElementById("bgm").play();
        }

        let path = "/gameSetting";
        navigate(path, {
          state: {
            lang: lang,
          },
        });
      // }
    }, BUTTON_ACTION_DELAY);
  };
  const onYellowClicked = () => {
    setTimeout(function () {
      let pdfFile = (lang === LANG.ENG) ? 
        "/doc/Rules_Eng_final_101023.pdf" 
        : "/doc/Rules_Chi_final_101023.pdf";
      window.open(pdfFile, "_blank");
      // handleGameRuleOpenDialog();
    }, BUTTON_ACTION_DELAY);
  };

  const toggleLang = () => {
    setLang(lang === LANG.CHI ? LANG.ENG : LANG.CHI);
  };

  return (
    <>
      <Dialog open={openGameRuleDialog} onClose={handleGameRuleCloseDialog}>
        <DialogTitle>
          <IconButton
            style={{ position: "absolute", right: "0px", top: "0px" }}
            onClick={handleGameRuleCloseDialog}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width:"50vh" , height:"80vh" }}>
          <object
            data={
              lang === LANG.ENG
                ? "/doc/Rules_Eng_final_101023.pdf"
                : "/doc/Rules_Chi_final_101023.pdf"
            }
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>
              <a
                href={
                  lang === LANG.ENG
                    ? "/doc/Rules_Eng_final_101023.pdf"
                    : "/doc/Rules_Chi_final_101023.pdf"
                }
              ></a>
            </p>
          </object>
          <Button onClick={handleGameRuleCloseDialog}>
            {lang === LANG.ENG ? "Close" : "關閉"}
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <IconButton
            style={{ position: "absolute", right: "0px", top: "0px" }}
            onClick={handleCloseDialog}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent  sx={{ width:"50vh" , height:"80vh" }}>
          <object
            data={
              lang === LANG.ENG
                ? "/doc/TC_Eng_final_040124.pdf"
                : "/doc/TC_Chi_final_040124.pdf"
            }
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>
              <a
                href={
                  lang === LANG.ENG
                    ? "/doc/TC_Eng_final_040124.pdf"
                    : "/doc/TC_Chi_final_040124.pdf"
                }
              ></a>
            </p>
          </object>
          <Button onClick={handleCloseDialog}>
            {lang === LANG.ENG ? "Close" : "關閉"}
          </Button>
        </DialogContent>
      </Dialog>
      <GameCanvas lang={lang}>
        <Box
          display="flex"
          position="relative"
          justifyContent="center"
          alignItems="flex-start"
          width="100%"
          height="75%"
        >
          <Box
            display="flex"
            position="absolute"
            top="0"
            width="100%"
            height="75%"
            justifyContent="center"
            alignItems="flex-start"
            bgcolor={COLOR.BACKGROUND.WHITE}
          ></Box>
          <Box
            display="flex"
            position="absolute"
            top="0"
            left="0"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/Character_A_300.png"
              duration={0}
              width={ isDesktop ? "16.5vh" : "15vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="-1.5%"
            right="-2%"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/Character_C_300.png"
              duration={0}
              width={ isDesktop ? "23vh" : "20vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="22vh"
            left="-1vh"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/Character_B_300.png"
              duration={0}
              width={ isDesktop ? "22.5vh" : "20vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="33%"
            right="-5%"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/Character_D_300.png"
              duration={0}
              width={ isDesktop ? "22vh" : "20vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="13%"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Image
              src="images/xmas.svg"
              duration={0}
              width={ isDesktop ? "28vh" : "25vh"} 
              height="auto"
            />
          </Box>
          <Box
            display="flex"
            position="absolute"
            bottom="0"
            width="100%"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Grid container>
              <Grid item xs={6} sx={{ p: 1 }}>
                <SvgButton
                  buttonStyle={(lang === LANG.ENG ? "Btn01_En":"Btn01_Ch")}
                  textSize="2.5vh"
                  onClick={onGreenClicked}
                />
              </Grid>
              <Grid item xs={6} sx={{ p: 1 }}>
                <SvgButton
                  buttonStyle={(lang === LANG.ENG ? "Btn02_En":"Btn02_Ch")}
                  textSize="2.5vh"
                  onClick={onYellowClicked}
                />
              </Grid>
              <Grid item xs={6} padding={0}>
                <Box
                  padding={0}
                  margin={0}
                  onClick={toggleLang}
                  marginLeft="3vh"
                  marginBottom="3vh"
                >
                  <Typography
                    style={{
                      fontVariant: "normal",
                      fontWeight: 900,
                      fontStretch: "normal",
                      fontSize: "2vh",
                      fontFamily:
                        "'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                      color: "#ffffff",
                      marginTop: "0px",
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        cursor: "hand",
                      },
                    }}
                  >
                    {lang !== LANG.ENG ? "ENG" : "中文"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </GameCanvas>
    </>
  );
}
