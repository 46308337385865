import { HashRouter, Routes, Route } from "react-router-dom";
import MainManu from "./pages/MainManu";
import GameSetting from "./pages/GameSetting";
import Game from "./pages/Game";
import Loading from "./pages/Loading";
import Coupon1 from "./pages/Coupon1";
import Coupon2 from "./pages/Coupon2";
import GameEnd from "./pages/GameEnd";

// export default function App() {
//   return (
//     <HashRouter>
//       <Routes>
//         <Route path="/">
//           <Route index element={<Loading />} />
//           <Route path="main" element={<MainManu needTnC="Y"/>} />
//           <Route path="gameSetting" element={<GameSetting />} />
//           <Route path="game" element={<Game />} />
//           <Route path="coupon1" element={<Coupon1 />} />
//           <Route path="coupon2" element={<Coupon2 />} />
//           <Route path="*" element={<MainManu  needTnC="Y"/>} />
//           <Route path="gameEnd" element={<GameEnd />} />
//         </Route>
//       </Routes>
//     </HashRouter>
//   );
// }

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/">
          <Route index element={<GameEnd/>} />
          <Route path="*" element={<GameEnd/>} />
          <Route path="gameEnd" element={<GameEnd/>} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

