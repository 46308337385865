export const COLOR = {
  BACKGROUND: { RED: "#ea002a", WHITE: "#FFFFFF", DARK_GREEN: "#566f5a" ,BLACK:"#000000"},
};

export const PLAYER = {
  PLAYER: "PLAYER",
  SPY: "SPY",
  BLANK: "BLANK",
};

export const BUTTON_ANIMATE_DELAY = "0.3s";
export const BUTTON_ACTION_DELAY = 500;

export const LANG = { ENG: "ENG", CHI: "CHI" };

export const QUESTION_INDEX = {
  SHOW_TEXT_PLAYER : 0 ,
  SHOW_TEXT_SPY : 1 , 
  IMAGE_PLAYER_CHI: 2,
  IMAGE_SPY_CHI: 3,
  IMAGE_PLAYER_ENG: 4,
  IMAGE_SPY_ENG: 5,
  CHI_TEXT_PLAYER: 6,
  CHI_TEXT_SPY: 7,
  ENG_TEXT_PLAYER: 8,
  ENG_TEXT_SPY: 9,
};

export const QUESTION = [
  ["Y","Y","food_images/food01.png","food_images/food02.png","food_images/food01.png","food_images/food02.png","香辣脆雞","家鄉雞","Hot & Spicy Chicken","Original Recipe Chicken"],
  ["Y","Y","food_images/food03.png","food_images/food04.png","food_images/food03.png","food_images/food04.png","辣汁蘑菇香飯","家鄉雞皇飯","Mushroom Rice","Chicken A La King Rice"],
  ["Y","Y","food_images/food04.png","food_images/food05.png","food_images/food04.png","food_images/food05.png","家鄉雞皇飯","燒雞扒雞皇芝士焗飯","Chicken A La King Rice","Cheesy Chicken A La King Baked Rice"],
  ["Y","Y","food_images/food06.png", "food_images/food07.png","food_images/food06.png", "food_images/food07.png", "粗薯條", "馬鈴薯蓉", "Chips", "Mashed Potato & Gravy"],
  ["Y","Y","food_images/food08.png", "food_images/food13.png","food_images/food08.png", "food_images/food13.png", "巴辣香雞翼", "上校雞寶", "Hot Wings", "Colonel Chicken Nuggets"],
  ["Y","Y","food_images/food09.png","food_images/food10.png","food_images/food09.png","food_images/food10.png","巴辣雞腿包","超勁⚡辣黃醬雞卷","Zinger Burger","Supercharger Twister"],
  ["Y","N","food_images/food11.png", "food_images/hk_tart_ch.svg","food_images/food11.png", "food_images/hk_tart_en.svg", "經典葡撻", "港式蛋撻", "KFC Egg Tart", "Hong Kong Style Egg Tart"],
  ["Y","N","food_images/food14.png", "food_images/fried_fish_ch.svg","food_images/food14.png", "food_images/fried_fish_en.svg", "炸雞", "炸魚", "Fried Chicken", "Fried Fish"],
  ["Y","Y","food_images/food16.png", "food_images/food17.png","food_images/food16.png", "food_images/food17.png", "百事可樂", "百事可樂無糖", "Pepsi Cola", "Pepsi Black"],
  ["N","N","food_images/trapezoid_ch.svg", "food_images/rectangle_ch.svg","food_images/trapezoid_en.svg", "food_images/rectangle_en.svg", "梯形", "長方形", "Trapezoid", "Rectangle"],
  ["N","N","food_images/facial_cleanser_ch.svg", "food_images/facial_scrub_ch.svg","food_images/facial_cleanser_en.svg", "food_images/facial_scrub_en.svg", "洗面膏", "面部磨砂膏", "Facial Cleanser", "Facial Scrub"],
  ["N","N","food_images/fan_ch.svg", "food_images/airconditioner_ch.svg","food_images/fan_en.svg", "food_images/airconditioner_en.svg", "風扇", "冷氣機", "Fan", "Air Conditioner"],
  ["N","N","food_images/laptop_ch.svg", "food_images/tablet_ch.svg","food_images/laptop_en.svg", "food_images/tablet_en.svg", "手提電腦", "平板電腦", "Laptop", "Tablet"],
  ["N","N","food_images/tst_ch.svg", "food_images/causewaybay_ch.svg","food_images/tst_en.svg", "food_images/causewaybay_en.svg", "尖沙咀", "銅鑼灣", "Tsim Sha Tsui", "Causeway Bay"],
  ["N","N","food_images/mtr_ch.svg", "food_images/bus_ch.svg","food_images/mtr_en.svg", "food_images/bus_en.svg", "地鐵", "巴士", "MTR", "Bus"],
  ["N","N","food_images/christmas_ch.svg", "food_images/newyear_ch.svg","food_images/christmas_en.svg", "food_images/newyear_en.svg", "聖誕", "新年", "Christmas", "New Year"],
];
