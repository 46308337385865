import { forwardRef } from "react";
import PropTypes from "prop-types";

import {
  BUTTON_ACTION_DELAY,
  BUTTON_ANIMATE_DELAY,
} from "../../common/constant";
import { ReactComponent as Btn01En } from "../../svg/btn_01_green_en.svg";
import { ReactComponent as Btn02En } from "../../svg/btn_02_yellow_en.svg";
import { ReactComponent as Btn03En } from "../../svg/btn_03_green_en.svg";
import { ReactComponent as Btn04En } from "../../svg/btn_04_blue_en.svg";
import { ReactComponent as Btn05En } from "../../svg/btn_05_green_en.svg";
import { ReactComponent as Btn06En } from "../../svg/btn_06_yellow_en.svg";
import { ReactComponent as Btn07En } from "../../svg/btn_07_blue_en.svg";
import { ReactComponent as Btn08En } from "../../svg/btn_08_green_en.svg";
import { ReactComponent as Btn01Ch } from "../../svg/btn_01_green_ch.svg";
import { ReactComponent as Btn02Ch } from "../../svg/btn_02_yellow_ch.svg";
import { ReactComponent as Btn03Ch } from "../../svg/btn_03_green_ch.svg";
import { ReactComponent as Btn04Ch } from "../../svg/btn_04_blue_ch.svg";
import { ReactComponent as Btn05Ch } from "../../svg/btn_05_green_ch.svg";
import { ReactComponent as Btn06Ch } from "../../svg/btn_06_yellow_ch.svg";
import { ReactComponent as Btn07Ch } from "../../svg/btn_07_blue_ch.svg";
import { ReactComponent as Btn08Ch } from "../../svg/btn_08_green_ch.svg";

import { Box, Button, Typography } from "@mui/material";

const SvgButton = forwardRef(
  (
    {
      buttonStyle,
      textSize,
      lineHeight,
      onClick,
      scale,
      textMarginTop,
      children,
    },
    ref
  ) => {
    let btn;
    let styleChangeHandler;
    switch (buttonStyle) {
      // green
      case "Btn01_En":
      case "Btn03_En":
      case "Btn05_En":
      case "Btn08_En":
      case "Btn01_Ch":
      case "Btn03_Ch":
      case "Btn05_Ch":
      case "Btn08_Ch":
        styleChangeHandler = () => {
          document.getElementById("g1172").style.display = "none";
          document.getElementById("g1168").style.display = "none";
          document.getElementById("text-g-h").style.display = "none";
          document.getElementById("text-g-l").style.display = "";
          let a = document.getElementById("path1162");
          a.style.fill = "#043715";
          a.style.transition = BUTTON_ANIMATE_DELAY;
          setTimeout(() => {
            document.getElementById("g1172").style.display = "";
            document.getElementById("g1168").style.display = "";
            document.getElementById("text-g-h").style.display = "";
            document.getElementById("text-g-l").style.display = "none";
            let a = document.getElementById("path1162");
            a.style.fill = "#0ca951";
          }, BUTTON_ACTION_DELAY);
        };
        btn = (buttonStyle === "Btn01_En") 
          ? (<Box width={scale} height="auto"><Btn01En onClick={styleChangeHandler}/></Box>)
          : (buttonStyle === "Btn03_En") 
            ? (<Box width={scale} height="auto"><Btn03En onClick={styleChangeHandler}/></Box>)
            : (buttonStyle === "Btn05_En") 
              ? (<Box width={scale} height="auto"><Btn05En onClick={styleChangeHandler}/></Box>)
              : (buttonStyle === "Btn01_Ch") 
                ? (<Box width={scale} height="auto"><Btn01Ch onClick={styleChangeHandler}/></Box>)
                : (buttonStyle === "Btn03_Ch") 
                  ? (<Box width={scale} height="auto"><Btn03Ch onClick={styleChangeHandler}/></Box>)
                  : (buttonStyle === "Btn05_Ch")  
                    ? (<Box width={scale} height="auto"><Btn05Ch onClick={styleChangeHandler}/></Box>)
                    : (buttonStyle === "Btn08_Ch") 
                      ? (<Box width={scale} height="auto"><Btn08Ch onClick={styleChangeHandler}/></Box>)
                      : (<Box width={scale} height="auto"><Btn08En onClick={styleChangeHandler}/></Box>)
        ;
        break;
      // blue
      case "Btn04_En":
      case "Btn07_En":
      case "Btn04_Ch":
      case "Btn07_Ch":
        styleChangeHandler = () => {
          document.getElementById("g446").style.display = "none";
          document.getElementById("g442").style.display = "none";
          document.getElementById("text-b-h").style.display = "none";
          document.getElementById("text-b-l").style.display = "";
          let a = document.getElementById("path436");
          a.style.fill = "#043715";
          a.style.transition = BUTTON_ANIMATE_DELAY;
          setTimeout(() => {
            document.getElementById("g446").style.display = "";
            document.getElementById("g442").style.display = "";
            document.getElementById("text-b-h").style.display = "";
            document.getElementById("text-b-l").style.display = "none";  
            let a = document.getElementById("path436");
            a.style.fill = "#42b4e7";
          }, BUTTON_ACTION_DELAY);
        };
        btn = (buttonStyle === "Btn04_En") 
          ? (<Box width={scale} height="auto"><Btn04En onClick={styleChangeHandler}/></Box>)
          : (buttonStyle === "Btn07_En") 
            ? (<Box width={scale} height="auto"><Btn07En onClick={styleChangeHandler}/></Box>)
            : (buttonStyle === "Btn04_Ch") 
              ? (<Box width={scale} height="auto"><Btn04Ch onClick={styleChangeHandler}/></Box>)
              : (<Box width={scale} height="auto"><Btn07Ch onClick={styleChangeHandler}/></Box>)
        ;
        break;
      // yellow
      case "Btn02_En":
      case "Btn06_En":
      case "Btn02_Ch":
      case "Btn06_Ch":
        styleChangeHandler = () => {
          document.getElementById("g1212").style.display = "none";
          document.getElementById("g1208").style.display = "none";
          document.getElementById("text-y-h").style.display = "none";
          document.getElementById("text-y-l").style.display = "";
          let a = document.getElementById("path1202");
          a.style.fill = "#043715";
          a.style.transition = BUTTON_ANIMATE_DELAY;
          setTimeout(() => {
            document.getElementById("g1212").style.display = "";
            document.getElementById("g1208").style.display = "";
            document.getElementById("text-y-h").style.display = "";
            document.getElementById("text-y-l").style.display = "none";
              let a = document.getElementById("path1202");
            a.style.fill = "#e9b520";
          }, BUTTON_ACTION_DELAY);
        };
        btn = (buttonStyle === "Btn02_En") 
          ? (<Box width={scale} height="auto"><Btn02En onClick={styleChangeHandler}/></Box>)
          : (buttonStyle === "Btn06_En") 
            ? (<Box width={scale} height="auto"><Btn06En onClick={styleChangeHandler}/></Box>)
            : (buttonStyle === "Btn02_Ch") 
              ? (<Box width={scale} height="auto"><Btn02Ch onClick={styleChangeHandler}/></Box>)
              : (<Box width={scale} height="auto"><Btn06Ch onClick={styleChangeHandler}/></Box>)
        ;
        break;
      default:
        btn = <></>;
    }

    return (
      <Box display="flex" justifyContent="flex-start" position="relative">
        <Button
          onClick={() => {
            styleChangeHandler();
            onClick();
          }}
          sx={{ m: 0, p: 0, textTransform: "none" }}
          disableRipple
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            width="100%"
            height="100%"
          >
            {btn}
            {children ? (
              <Box
                position="absolute"
                height="30%"
                width="auto"
                marginTop={textMarginTop ? textMarginTop : "3%"}
              >
                <Typography
                  style={{
                    fontVariant: "normal",
                    fontWeight: 900,
                    fontStretch: "normal",
                    fontSize: textSize ? textSize : "10px",
                    lineHeight: lineHeight ? lineHeight : "",
                    fontFamily:
                      "'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                    color: "#ffffff",
                    marginTop: "0px",
                  }}
                >
                  {children}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Button>
      </Box>
    );
  }
);

SvgButton.propTypes = {
  buttonStyle: PropTypes.string.isRequired,
  textSize: PropTypes.string,
  lineHeight: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  scale: PropTypes.string,
};

export default SvgButton;
