import { useState, useEffect } from "react";

import GameCanvas from "../../components/GameCanvas";
import BottomNavigator from "../../components/BottomNavigator";
import WordBubble from "../../components/WordBubble";
import SvgButton from "../../components/SvgButton";
import Image from "mui-image";
import { motion } from "framer-motion";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate, useLocation } from "react-router-dom";
import { COLOR, BUTTON_ACTION_DELAY, LANG } from "../../common/constant";
const { detect } = require('detect-browser');
const browser = detect();

export default function Coupon2() {
  const browserOs = (browser.os).toLocaleLowerCase().split(" ") ;
  const isDesktop = !(browserOs.includes('android') || browserOs.includes('ios'));

  let navigate = useNavigate();

  const location = useLocation();

  const lang =
    location.state && location.state.lang ? location.state.lang : LANG.CHI;

  const questionNumber =
    location.state && location.state.questionNumber ? location.state.questionNumber : 0;

  const [wordBubbleAnimation, setWordBubbleAnimation] = useState("closed");
  const wordBubbleVariants = {
    open: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
    closed: { opacity: 0, scale: 0, transition: { duration: 0 } },
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setTimeout(function () {
      setWordBubbleAnimation("open");
    }, 500);
  }, []);

  const onBackClicked = () => {
    const path = "/game";
    navigate(path, {
      state: {
        gameStage: 3,
        lang: lang,
        questionNumber : questionNumber
      },
    });
  };
  const onHomeClicked = () => {
    const path = "/main";
    navigate(path, {
      state: {
        lang: lang,
        needTnC: "N",
      },
    });
  };
  const onClickTnc = () => {
    let pdfFile = (lang === LANG.ENG) 
        ? "/doc/TC_Eng_final_040124.pdf"
        : "/doc/TC_Chi_final_040124.pdf"
    ;
    window.open(pdfFile, "_blank");
    // handleOpenDialog();
  };
  const onBlueClicked = () => {
    setTimeout(function () {
      const path = "/gameSetting";
      navigate(path, {
        state: {
          lang: lang,
        },
      });
    }, BUTTON_ACTION_DELAY);
  };
  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <IconButton
            style={{ position: "absolute", right: "0px", top: "0px" }}
            onClick={handleCloseDialog}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: "50vh", height: "80vh" }}>
          <object
            data={
              lang === LANG.ENG
                ? "/doc/TC_Eng_final_040124.pdf"
                : "/doc/TC_Chi_final_040124.pdf"
            }
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>
              <a
                href={
                  lang === LANG.ENG
                    ? "/doc/TC_Eng_final_040124.pdf"
                    : "/doc/TC_Chi_final_040124.pdf"
                }
              ></a>
            </p>
          </object>
          <Button onClick={handleCloseDialog}>
            {lang === LANG.ENG ? "Close" : "關閉"}
          </Button>
        </DialogContent>
      </Dialog>
      <GameCanvas lang={lang}>
        <Box
          display="flex"
          position="relative"
          justifyContent="center"
          alignItems="flex-start"
          width="100%"
          height="75%"
        >
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              width: "100%",
              height: "85%",
              backgroundImage: "url('/images/background.jpg')",
              backgroundRepeat: "repeat",
            }}
          >
            <div
              top="0"
              style={{
                width:"100%",
                height:isDesktop?"77%":"75%",
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                marginTop={isDesktop?"-3%":"3%"}
              >
                  <motion.div
                    initial="closed"
                    animate={wordBubbleAnimation}
                    variants={wordBubbleVariants}
                  >
                    <WordBubble
                      src="images/word_bubble_yellow.svg"
                      textSize={lang === LANG.ENG ? "1.6vh" : "2vh"}
                      scale="25vh"
                      lineHeight={1.2}
                      marginTop={lang === LANG.ENG ? "0.2vh" : "1.5vh"}
                    >
                      {lang === LANG.ENG ? (
                        <>
                          Can't find <br />
                          the "Hungry" Spy / Nobody<br />
                          Let's get some food and
                          <br />
                          play again!
                        </>
                      ) : (
                        <>
                          搵唔到「餓」底唔緊要,
                          <br />
                          食飽再玩過!
                        </>
                      )}
                    </WordBubble>
                  </motion.div>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                marginTop={isDesktop?"-3%":""}
              >
                <Image
                  src="images/coupon2_600.jpg"
                  duration={0}
                  width="90%"
                  height="auto"
                />
                <Typography
                    style={{
                      fontVariant: "normal",
                      fontWeight: 900,
                      fontStretch: "normal",
                      fontSize: "1.5vh",
                      fontFamily:
                        "'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                      color: "#000000",
                      marginTop: "0px",
                      backgroundColor:"#ffffff"
                    }}
                  >
                    {lang === LANG.ENG 
                      ? ("Coupon Expiry Date:  9/2/2024")
                      : ("優惠券到期日: 9/2/2024")
                    }
                  </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                width="100%"
                height="100%"
                marginTop={isDesktop?"3%":"-3%"}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "hand",
                  },
                }}
              >
                  <img src={
                    lang === LANG.ENG
                    ? "images/coupon2_text_en.svg"
                    : "images/coupon2_text_ch.svg"
                  }
                  width="100%"
                  onClick={onClickTnc}
                  />
              </Box>
            </div>
          </Box>
          <Box
            position="absolute"
            bottom="12.2%"
            width="100%"
            height="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: COLOR.BACKGROUND.RED }}
            margin={0}
          >
            <Box width="70%" height="auto" marginTop="1%">
              <SvgButton
                buttonStyle={(lang === LANG.ENG ? "Btn07_En":"Btn07_Ch")}
                textSize={lang === LANG.ENG ? "2.3vh" : "3vh"}
                onClick={onBlueClicked}
                scale="60%"
              />
            </Box>
          </Box>
          {BottomNavigator(onHomeClicked, onBackClicked, null, null)}
          <Box
            display="flex"
            position="absolute"
            bottom="0"
            width="70%"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Grid container>
              <Grid item xs={12} padding={0}>
                <Box padding={0} margin={0} marginLeft="1vh" marginBottom="3vh">
                  {lang === LANG.ENG ? (
                    <Typography
                      style={{
                        fontVariant: "normal",
                        fontWeight: 900,
                        fontStretch: "normal",
                        fontSize: "1.15vh",
                        fontFamily:
                          "'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                        color: "#FFFFFF",
                        marginTop: "0px",
                        lineHeight: "1.3",
                      }}
                    >
                      There's a consolation prize for everyone!
                      <br />
                      Go to the previous page to get it!
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        fontVariant: "normal",
                        fontWeight: 900,
                        fontStretch: "normal",
                        fontSize: "1.5vh",
                        fontFamily:
                          "'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                        color: "#FFFFFF",
                        marginTop: "0px",
                      }}
                    >
                      大家都有安慰獎！
                      <br />
                      即去前頁領取啦！
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </GameCanvas>
    </>
  );
}
