import { useState, useEffect } from "react";
import GameCanvas from "../../components/GameCanvas";
import SvgButton from "../../components/SvgButton";
import BottomNavigator from "../../components/BottomNavigator";
import Image from "mui-image";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BUTTON_ACTION_DELAY,
  QUESTION,
  LANG,
} from "../../common/constant";
import { motion } from "framer-motion";
const { detect } = require('detect-browser');
const browser = detect();

export default function GameSetting() {
  const browserOs = (browser.os).toLocaleLowerCase().split(" ") ;
  const isDesktop = !(browserOs.includes('android') || browserOs.includes('ios'));
  
  const navigate = useNavigate();

  const location = useLocation();
  const lang =
    location.state && location.state.lang ? location.state.lang : LANG.CHI;

  const [boardAnimation, setBoardAnimation] = useState("closed");
  const boardVariants = {
    open: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: { duration: 1 },
      width: "100%",
    },
    closed: {
      opacity: 1,
      scale: 0,
      y: "10vh",
      transition: { duration: 0 },
      width: "100%",
    },
  };

  useEffect(() => {
    setTimeout(function () {
      setBoardAnimation("open");
    }, 500);
  }, []);

  const Game_Board_Text = (text) => (
    <Typography
      style={{
        fontVariant: "normal",
        fontWeight: 900,
        fontStretch: "normal",
        fontSize: isDesktop ? "3vh": "2.7vh",
        fontFamily: 
          "'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        color: "#ffffff",
        marginTop: isDesktop ? "0.4vh":"0.2vh",
        marginLeft: isDesktop ? "0.6vh":"0.6vh",
      }}
    >
      {text}
    </Typography>
  );

  const Game_Board_Btn = (text) => (
    <Typography
      style={{
        fontVariant: "normal",
        fontWeight: 900,
        fontStretch: "normal",
        fontSize: isDesktop ? "3vh": "2.7vh",
        fontFamily:
          "'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        color: "#ffffff",
        margin: "1px",
      }}
    >
      {text}
    </Typography>
  );

  const maxPlayer = 12;
  const minPlayer = 4;
  const minSpy = 1;
  const maxBlank = 1;
  const minBlank = 0;

  let isPlayingBgm = !document.getElementById("bgm").paused;

  const [playBgm, setPlayBgm] = useState(isPlayingBgm);
  const [playerCount, setPlayerCount] = useState(minPlayer);
  const [spyCount, setSpyCount] = useState(minSpy);
  const [blankCount, setBlankCount] = useState(minBlank);
  const [questionNumber] = useState(
    Math.floor(Math.random() * QUESTION.length)
  );

  const addPlayerEnabled = () => {
    return playerCount < maxPlayer;
  };
  const minusPlayerEnabled = () => {
    return playerCount > minPlayer;
  };
  const addBlankEnabled = () => {
    return (
      blankCount < maxBlank &&
      Math.ceil(playerCount / 4) - spyCount - blankCount > 0
    );
  };
  const minusBlankEnabled = () => {
    return blankCount > minBlank;
  };
  const addSpyEnabled = () => {
    return Math.ceil(playerCount / 4) - spyCount - blankCount > 0;
  };
  const minusSpyEnabled = () => {
    return spyCount > minSpy;
  };

  const addPlayer = () => {
    if (addPlayerEnabled()) setPlayerCount(playerCount + 1);
  };
  const minusPlayer = () => {
    if (minusPlayerEnabled()) {
      let newPlayerCount = playerCount - 1;
      setPlayerCount(newPlayerCount);
      if (Math.ceil(newPlayerCount / 4) < spyCount + blankCount) {
        if (spyCount > minSpy) {
          setSpyCount(spyCount - 1);
        } else {
          setBlankCount(minBlank);
        }
      }
    }
  };
  const addSpy = () => {
    if (addSpyEnabled()) setSpyCount(spyCount + 1);
  };
  const minusSpy = () => {
    if (minusSpyEnabled()) setSpyCount(spyCount - 1);
  };
  const addBlank = () => {
    if (addBlankEnabled()) setBlankCount(blankCount + 1);
  };
  const minusBlank = () => {
    if (minusBlankEnabled()) setBlankCount(blankCount - 1);
  };

  const onGreenClicked = () => {
    setTimeout(function () {
      const path = "/game";
      navigate(path, {
        state: {
          playerCount: playerCount,
          spyCount: spyCount,
          blankCount: blankCount,
          questionNumber: questionNumber,
          lang: lang,
        },
      });
    }, BUTTON_ACTION_DELAY);
  };
  const onHomeClicked = () => {
    const path = "/main";
    navigate(path, {
      state: {
        lang: lang,
        needTnC: "N",
      },
    });
  };
  const onPlayBgmClicked = () => {
    let newPlayBgm = !playBgm;
    setPlayBgm(newPlayBgm);
    if (newPlayBgm) {
      document.getElementById("bgm").play();
    } else {
      document.getElementById("bgm").pause();
    }
  };
  return (
    <GameCanvas lang={lang}>
      <Box
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems="flex-start"
        width="100%"
        height="75%"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            height: "73%",
            backgroundImage: "url('/images/background.jpg')",
            backgroundRepeat: "repeat",
          }}
        >
          <Box width="75%" height="auto" marginTop="-20%">
            <motion.div
              initial="closed"
              animate={boardAnimation}
              variants={boardVariants}
            >
              <Image
                src={ lang === LANG.ENG ? "images/game_board_with_text_en.png" : "images/game_board_with_text_ch.png"}
                duration={0}
                width="100%"
                height="auto"
                pointerEvents="none"
              />
            </motion.div>
          </Box>
        </Box>

        <Box
          display="flex"
          position="absolute"
          top="18%"
          width="70%"
          justifyContent="center"
          alignItems="center"
        >
          <motion.div
            initial="closed"
            animate={boardAnimation}
            variants={boardVariants}
          >
            <Grid container mx="0px" my="0px" sx={{ width: "100%" }}>
              <Grid item xs={7} sx={{ m: 0, p: 0 }}></Grid>
              <Grid
                item
                xs={1}
                onClick={minusPlayer}
                sx={{
                  m: 0,
                  p: 0,
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "hand",
                  },
                }}
              >
                {Game_Board_Btn("-")}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  m: 0,
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Game_Board_Text(playerCount)}
              </Grid>
              <Grid
                item
                xs={2}
                onClick={addPlayer}
                sx={{
                  display: "flex",
                  m: 0,
                  p: 0,
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "hand",
                  },
                }}
              >
                {Game_Board_Btn("+")}
              </Grid>

              <Grid item xs={7}></Grid>
              <Grid
                item
                xs={1}
                onClick={minusSpy}
                sx={{
                  m: 0,
                  p: 0,
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "hand",
                  },
                }}
              >
                {Game_Board_Btn("-")}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  m: 0,
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Game_Board_Text(spyCount)}
              </Grid>
              <Grid
                item
                xs={2}
                onClick={addSpy}
                sx={{
                  display: "flex",
                  m: 0,
                  p: 0,
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "hand",
                  },
                }}
              >
                {Game_Board_Btn("+")}
              </Grid>

              <Grid item xs={7}></Grid>
              <Grid
                item
                xs={1}
                onClick={minusBlank}
                sx={{
                  m: 0,
                  p: 0,
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "hand",
                  },
                }}
              >
                {Game_Board_Btn("-")}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  m: 0,
                  p: 0,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Game_Board_Text(blankCount)}
              </Grid>
              <Grid
                item
                xs={2}
                onClick={addBlank}
                sx={{
                  display: "flex",
                  m: 0,
                  p: 0,
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "hand",
                  },
                }}
              >
                {Game_Board_Btn("+")}
              </Grid>
            </Grid>
          </motion.div>
        </Box>

        <Box
          display="flex"
          position="absolute"
          top="74%"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <SvgButton
                buttonStyle={(lang === LANG.ENG ? "Btn08_En":"Btn08_Ch")}
                textSize={lang === LANG.ENG ?"1.8vh":"2.6vh"}
                textMarginTop={lang === LANG.ENG ?"1vh":"0.8vh"}
                onClick={onGreenClicked}
                scale="20vh"
              />
            </Grid>
          </Grid>
        </Box>
        {BottomNavigator(onHomeClicked, null, playBgm, onPlayBgmClicked)}
      </Box>
    </GameCanvas>
  );
}
