import { forwardRef } from "react";
import PropTypes from "prop-types";
import Image from "mui-image";
import { Container, Box } from "@mui/material";
import { COLOR , LANG} from "../../common/constant";
const { detect } = require('detect-browser');
const browser = detect();

const GameCanvas = forwardRef(({ lang , children,  ...rest }, ref) =>  
{
  const browserOs = (browser.os).toLocaleLowerCase().split(" ") ;
  const isDesktop = !(browserOs.includes('android') || browserOs.includes('ios'));
  return (
    <Box
      sx={{
        backgroundColor: COLOR.BACKGROUND.RED,
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          p: 0,
          justifyContent: "center",
          width: "100%",
          display: "flex",
          height: "auto",
        }}
      >
        <Box
          {...rest}
          ref={ref}
          sx={{
            backgroundColor: COLOR.BACKGROUND.RED,
            borderRadius: "0.1rem",
            p: 0,
            m: 0,
            aspectRatio: 1739 / 3755,
            height: isDesktop ? "100vh":"85vh",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box sx={{ aspectRatio: 1739 / 3755 }}>
            <Box sx={{ bgcolor: "#fff" }}>
              <Image
                src="images/Game_banner.jpg"
                duration={0}
                width="100%"
                height="auto"
              />
            </Box>
            <Image
              src={lang === LANG.ENG ? "images/TopBar_Eng.png" : "images/TopBar_Chi.png"}
              duration={0}
              width="100%"
              height="auto"
            />
            {children}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
);

GameCanvas.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string.isRequired,
};

export default GameCanvas;
